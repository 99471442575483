import React, { Component } from 'react'

import { LazyImageFull as LazyImage, ImageState } from "react-lazy-images"

class LazyBackground extends Component {
    render() {
        const bgClassName = this.props.bgClassName ? " " + this.props.bgClassName : ""
        const RootComponent = this.props.element
        return (
            <RootComponent className={this.props.className || ""} id={this.props.id || ""} ref={ this.props.forwardRef || null }>
            <LazyImage src={this.props.bgSrc} >
                {({ imageProps, imageState, ref }) => (
                    <div className={ `lazyBg${bgClassName}${ imageState === ImageState.LoadSuccess ? " lazyloaded" : " lazyload"}` }
                    ref={ref} 
                    {...imageProps}
                    style={{ backgroundImage: `url(${imageState === ImageState.LoadSuccess ? imageProps.src : this.props.bgSrc})` }}
                    />
                )}
            </LazyImage>
            {this.props.children}
            </RootComponent>
        )
    }
}

export default LazyBackground

