import React, { Component, createRef } from 'react'
import SiteHeader from './SiteHeader'
import { injectIntl } from "gatsby-plugin-intl"
import LazyImageWrapper from './LazyImageWrapper'
import LazyBackground from './LazyBackground'
import appstore from '../img/appstore.svg'
import '../css/style_mission.scss'

class Mission extends Component {

    constructor(props, context) {
        super(props, context)
        this.state = {
            height: 0, 
            width: 0, 
            pixelRatio: 1, 
            viewInitialized: false, 
            scrollTop: 0
        }
        this.initialized = false
        this.missionTop = React.createRef()
    }

    componentDidMount() {
        if (!this.initialized) {
            this.initialized = true
            window.addEventListener('scroll', this.updateScroll)
        }
        if (this.state.viewInitialized === false) {
            this.setState({
                viewInitialized: true, 
                height: window.innerHeight, 
                width: window.innerWidth,
                pixelRatio: window.devicePixelRatio || 1
            })
        }
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.updateScroll)
    }

    updateScroll = (event) => {
        this.setState({ scrollTop: this.getScrollTop() })
    }
       
    getScrollTop = () => {
        return document?.documentElement?.scrollTop || document?.body?.scrollTop || window?.pageYOffset || 0
    }

    render() {
        let alpha = 0.4
        if (this.missionTop.current) {
            const missionTopHeight = this.missionTop.current.offsetHeight
            let scrollTopHalf = this.state.scrollTop - missionTopHeight / 2
            if (scrollTopHalf < 0) scrollTopHalf = 0
            alpha = 0.4 + scrollTopHalf / (missionTopHeight / 2) * 0.6
            if (alpha > 1) alpha = 1
        }
        const isJP = this.props.intl.locale === 'ja'
        const imageAndBadge = (<>
            <LazyImageWrapper src="/images/ph0a.jpg" srcSet="/images/ph0a.jpg 1x, /images/ph0a@2x.jpg 2x" alt="Screen Shots" />
		    <div id="bottom"><div className="appstore"><a href="https://itunes.apple.com/app/id1439882679?mt=8" id="appstore"><img src={appstore} alt="Download on the App Store" /></a></div></div>
            </>)

        return(<>
<SiteHeader {...this.locationProps} {...this.props} style={{ backgroundColor: `rgba(40, 40, 40, ${alpha})`}} />
<div id="container">
	<div id="missionTop" ref={this.missionTop}>
        <LazyBackground element="div" id="topImage" 
            bgSrc={ this.state.pixelRatio === 1 ? "/images/mbg.jpg" : "/images/mbg@2x.jpg" } >
            <h2 id="topTitle"><LazyImageWrapper src="/images/mlogo.png" srcSet="/images/mlogo.png 1x, /images/mlogo@2x.png 2x" alt="New Monaural" /></h2>
			{ isJP ?  
    			<h2 id="mission" className="jpTitle">「ステレオを捨てる」のではなく。</h2>
                :
    			<h2 id="mission" className="enTitle">Not "discarding stereo."</h2>
            }
        </LazyBackground>
    </div>
    { isJP ?  
    <div id="mainText" className="jpText">
        <h2 className="jpTitle">New Monauralのミッション、解決を目指していること</h2>
		<p>友達と一緒に、インイヤーヘッドフォンの左右を分けあって音楽を聴いた経験はきっと誰にでもあると思います。一側性難聴者がヘッドフォンで音楽を聴く場合、そのときとまったく同じ聞こえ方になります。片耳の聴力にハンディキャップがあるため、両耳にヘッドフォンをしても聞こえるのは常に一方のチャンネルだけになるからです。</p>
		<p>といっても、多くの楽曲は左右どちらかのチャンネルのみで聴いても特に違和感はありません。しかし、曲によっては時々、片側だけにヴォーカルや楽器を振り分けるように音像定位が作られているパートが出てくることがあります。分かりやすい例として挙げられるのは、Lenny Kravitzの有名曲 "Are you Gonna Go My Way" です。この曲はギターのリフがオクターブ違いで2本弾かれていて、それぞれが左右のチャンネルに完全に振り分けられています。</p>
		<iframe allow="autoplay *; encrypted-media *;" frameBorder="0" height="150" style={{ width: "100%",  overflow: "hidden", backgroundColor: "transparent" }} sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.music.apple.com/us/album/are-you-gonna-go-my-way/712353727?i=712353794&app=music&at=10lsGw"></iframe>
		<p>一側性難聴者がこの曲をヘッドフォンで聴いても楽曲として成立しないため、残念ですが楽しむことすらできません。これは最も極端な例のひとつですが、同じような問題はあらゆる楽曲で起こり得ます。</p>
		<p>プロデューサーやミキシングエンジニアは、ステレオを前提として音像定位に工夫をこらします。そして、片側のチャンネルだけで楽曲を聴くリスナーがいることは想定していません。その結果、聞こえる側のチャンネルが不自然なところで無音になったり、まるでカラオケのように何かが欠けたアレンジになる瞬間があったり、ということが曲によっては起きてしまいます。</p>
		<p>リスナーがこの問題に対処することを望むなら、モノラル再生用のイヤフォンや変換ケーブルが使えるかもしれません。スマートフォンで音楽を聴いているならもっと簡単な手段もあります。iOSやAndroidのアクセシビリティ機能（モノラルオーディオ）を有効にするだけで、普通のヘッドフォンのままですべての楽曲をモノラルで聴くことができます。</p>
        <LazyImageWrapper src="/images/monoaudio.jpg" srcSet="/images/monoaudio.jpg 1x, /images/monoaudio@2x.jpg 2x" alt="iOS Accessibility Screen" />
		<p>しかし、これらは一側性難聴者の問題を解決していると言えるでしょうか？</p>
		<p>ステレオを諦めて、その代わりに不都合を少しだけ解消する。楽曲の意図などをスポイルして、とりあえずモノラルにする。それが果たして私たちの望んでいること、腑に落ちる解決方法なのでしょうか。</p>
		<p>私たちは特別なことや大げさなことをすることなく、ただ普通に音楽を楽しみたいはずです。楽曲を健聴者と同じように、同じステレオを介して聴きたいはずです。仮に難聴が先天的なもので、ステレオ本来の聞こえ方を正確には知らなかったとしても、ライブに行ったりすれば音の広がりのようなものは当然感じます。決して単純に左右のチャンネルをひとつに合わせただけのモノラルな世界に生きているわけではないのです。</p>
		<p>もし、私たちがステレオスピーカーやライブで音楽を聴いているときの感覚を、モノラルでも同じように体験することができたら。自分にとっての普段通りの音楽を、ヘッドフォンでも手にすることができたら。それが、この問題に対してEmotionaleが導き出した解、そしてコンセプトの根幹です。</p>
		<p>究極の目標は、聴こえのハンディキャップから生じる制約に合わせて音楽をダウングレードしないこと。ステレオをモノラルへとスポイルするのではなく、新しいモノラルの概念を生み出すこと。New Monauralはその実現のためにデザインされたミュージックプレーヤーアプリです。</p>
        {imageAndBadge}
    </div>
	:
	<div id="mainText" className="enText">
		<h2 className="enTitle">New Monaural's mission, working toward a solution</h2>
		<p>Almost everybody knows what it is like to share a single pair of in ear headphones with a friend to listen to music. When people with unilateral hearing loss listen to music through headphones, they hear the music exactly like that. Because they cannot hear through one of their ears, even if they put headphones on both ears, all they will hear is a single channel.</p>
		<p>Fortunately, with most music if you listen to only the left or right channel alone, it will not sound particularly unnatural. However, depending on the music, sometimes part of the music is created with a sound image localization that does something such as separate vocals and instruments into separate channels. As an easy to understand example, consider the famous song "Are you Gonna Go My Way" by Lenny Kravitz. In this song two guitar riffs are played in different octaves, and these riffs are completely separated from each other into the left and right channels.</p>
		<iframe allow="autoplay *; encrypted-media *;" frameborder="0" height="150" style={{ width: "100%",  overflow: "hidden", backgroundColor: "transparent" }} sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.music.apple.com/us/album/are-you-gonna-go-my-way/712353727?i=712353794&app=music&at=10lsGw"></iframe>
		<p>When a person with unilateral hearing loss listens to this song through headphones, because they cannot hear the full song as intended, unfortunately it is not very enjoyable. This is an extreme example, but similar problems can occur in all kinds of music.</p>
		<p>Producers and mixing engineers take stereo audio as a given when creating a sound image localization. They do not consider that there could be people listening to only a single channel of the music. As a result, the one channel that the listener can hear might sometimes go silent, the arrangement might sound almost like karaoke for a moment as components go missing, or other oddities might occur depending on the song.</p>
		<p>If the listener wishes to do something about this issue, it might be possible to use monaural playback headphones or a conversion cable. If they are listening to the music on their smartphone, there is a simpler solution. On iOS and Android an accessibility function "Mono Audio" can be enabled, making it possible to listen to all music monaurally with normal headphones.</p>
        <LazyImageWrapper src="/images/monoaudio_en.jpg" srcSet="/images/monoaudio_en.jpg 1x, /images/monoaudio_en@2x.jpg 2x" alt="iOS Accessibility Screen" />
		<p>However, does this actually solve the problem for listeners with unilateral hearing loss?</p>
		<p>This solution gives up on stereo, and replaces it with something that slightly alleviates the problem. It spoils the intent of the music, settling for simply making it monaural. Is this the solution we were hoping for, one we can be happy with?</p>
		<p>We simply want to enjoy music normally, without having to jump through a lot of hoops. Just like a person with normal hearing, we want to be able to listen to music through stereo. Even if our hearing loss is congenital, even if we do not know precisely what it is like to listen to stereo music, naturally when we are at a live concert we can feel the depth of the music. Surely we are not living in a monaural world where the left and right channels are simply combined together.</p>
		<p>That sensation that we get from listening to music through stereo speakers or at a live show, if only we could experience it the same way monaurally. If only we could experience it when listening to our usual music through headphones. This is at the core of the solution that Emotionale has been striving for, and it is the foundation of this concept.</p>
		<p>The ultimate goal is to avoid downgrading the music in conjunction with the limitations of being hard of hearing. It is not to simply spoil stereo sound into monaural sound, but rather to give birth to a new monaural idea. New Monaural is the music player app that was designed specifically to realize this goal.</p>
        {imageAndBadge}
    </div>
    }
</div>
</>
        )
    }
}

export default injectIntl(Mission)
