import React, { Component } from 'react'

import { LazyImageFull as LazyImage, LazyImage as LazyImageBg, ImageState } from "react-lazy-images"

class LazyImageWrapper extends Component {
  constructor(props, context) {
    super(props, context)
  }
  render() {
    if (this.props.bgSrc) {
      if (this.props.element === "div") {
        return (
          <LazyImageBg src={this.props.bgSrc} placeholder={({ ref }) => (
            <div ref={ref} className={this.props.className || ""} id={this.props.id || ""}
              style={{ backgroundImage: `url(${this.props.placeholder})` }}>
              {this.props.children}
            </div>
          )}
          actual={({ imageProps }) => (
            <div className={this.props.className || ""} id={this.props.id || ""}
              style={{ backgroundImage: `url(${imageProps.src})` }}>
              {this.props.children}
            </div>
          )}
        />
        )
      }
      else if (this.props.element === "h2") {
        return (
          <LazyImageBg src={this.props.bgSrc} placeholder={({ ref }) => (
            <h2 ref={ref} className={this.props.className || ""} id={this.props.id || ""}
              style={{ backgroundImage: `url(${this.props.placeholder})` }}>
              {this.props.children}
            </h2>
          )}
          actual={({ imageProps }) => (
            <h2 className={this.props.className || ""} id={this.props.id || ""}
              style={{ backgroundImage: `url(${imageProps.src})` }}>
              {this.props.children}
            </h2>
          )}
        />
        )
      }
    }

    return (
      <LazyImage srcSet={this.props.srcSet} >
      {({ imageProps, imageState, ref }) => (
          <img
          {...imageProps}
          ref={ref}
          alt={this.props.alt} 
          id={this.props.id} 
          src={
              imageState === ImageState.LoadSuccess
              ? imageProps.src
              : this.props.src 
          }
          style={{ ...this.props.style, opacity: ImageState.LoadSuccess ? "1" : "0" }}
          />
      )}
      </LazyImage>
    )
  }
}

export default LazyImageWrapper

