import React from 'react'
import SEO from '../../components/SEO'
import { Location } from '@reach/router';
import { useIntl } from "gatsby-plugin-intl"
import SiteFooter from '../../components/SiteFooter'
import Mission from '../../components/Mission'

function mission(props) {
    const intl = useIntl()
    return (
        <Location>
            {locationProps => (
                <>
                <SEO className="mission" title={intl.formatMessage({ id: 'mission.title' })} {...locationProps} 
                    description={intl.formatMessage({ id: "meta.mission.description" })} 
                    ogpDesc={intl.formatMessage({ id: "meta.mission.ogpDesc" })}
                    ogp={{
                        image: "/images/ogp_mission.jpg", 
                        twitterImage: "/images/ogp_mission.jpg", 
                        width: "1200", 
                        height: "630"
                    }} 
                />
                <Mission {...locationProps} {...props} />
                <SiteFooter {...locationProps} {...props} />
                </>
            )}
        </Location>
    )
}

export default mission

